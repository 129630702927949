<template>
    <div>

        <div class="row">
            <div class="col">
                <label>Keywords</label>
            </div>
            <div class="col">
                <label>Status</label>
            </div>
            <div class="col">
                <label>Type</label>
            </div>
            <div class="col-1">
                &nbsp;
            </div>
        </div>

        <div class="row">
            <div class="col">
                <input type="text" class="form-control" name="keywords" v-model="keywords" v-on:keyup.enter="search(1)" />
            </div>
            <div class="col">
                <select class="form-control form-select" name="state" v-model="state">
                    <option value="">Any</option>
                    <option v-for="state in states">
                        {{ state }}
                    </option>
                </select>
            </div>
            <div class="col">
                <select class="form-control form-select" name="type" v-model="type">
                    <option value="">Any</option>
                    <option v-for="type in types">
                        {{ type }}
                    </option>
                </select>
            </div>
            <div class="col col-1">
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary -pull-right" @click.prevent="search(1)">Search</button>
                </div>
            </div>
        </div>


        <hr />

        <div v-for="application in applications" v-if="applications">
            <div class="row">
                <div class="col">
                    <div class="row list-inline">
                        <div class="col-3">
                            <p class="list-label"><strong>Unique Reference:</strong></p>
                        </div>
                        <div class="col">
                            <p>{{ application.reference }}</p>
                        </div>
                    </div>
                    <div class="row list-inline">
                        <div class="col-3">
                            <p class="list-label"><strong>Applicant Name:</strong></p>
                        </div>
                        <div class="col">
                            <p>{{ application.applicant_name }}</p>
                        </div>
                    </div>

                    <div class="row list-inline">
                        <div class="col-3">
                            <p class="list-label"><strong>Type:</strong></p>
                        </div>
                        <div class="col">
                            <p>{{ application.type }}</p>
                        </div>
                    </div>

                    <div class="row list-inline">
                        <div class="col-3">
                            <p class="list-label"><strong>Status:</strong></p>
                        </div>
                        <div class="col">
                            <p>{{ application.status }}</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <a :href="application.url" class="btn btn-primary">View</a>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </div>

        <nav v-if="pagination">
            <ul class="pagination justify-content-center">
                <li v-for="link in pagination.links" class="page-item" :class="{ active: link.active, disabled: !link.url }">
                    <a class="page-link" href="#" v-html="link.label" @click.prevent="paginate(link.url)"></a>
                </li>
            </ul>
        </nav>
    </div>


</template>

<script>
export default {
    props: ['states', 'types'],
    data() {
        return {
            state: 'Submitted',
            type: null,
            keywords: null,
            applications: null,
            pagination: null
        }
    },
    mounted() {
        this.search()
    },
    methods: {
        async search(page = 1) {
            const { data } = await axios.get(`/admin/search`, {
                params: { state: this.state, type: this.type, keywords: this.keywords, page: page }
            })

            this.applications = data.data
            this.pagination = data

            this.$actions.setSearchCount(data.total)
        },
        async paginate(url) {
            const { data } = await axios.get(url, {
                params: { state: this.state, type: this.type, keywords: this.keywords  }
            })

            this.applications = data.data
            this.pagination = data
        }
    }
}
</script>
