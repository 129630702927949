require('./bootstrap');

import { reactive } from "vue";
import { createApp } from 'vue'
import PortalVue from 'portal-vue'

const app=createApp()
app.use(PortalVue)
app.component('custom-date-picker', require('./components/Datepicker').default);
app.component('file-input', require('./components/FileInput').default);
app.component('multiple-files', require('./components/MultipleFiles').default);
app.component('existing-files', require('./components/ExistingFiles').default);
app.component('admin', require('./components/Admin').default)
app.component('application-search', require('./components/ApplicationSearch').default)
app.component('messages', require('./components/Messages').default)
app.component('additional-files', require('./components/AdditionalFiles').default)
app.component('modal', require('./components/Modal').default)
app.component('additional-information', require('./components/AdditionalInformation').default)
app.component('application-status', require('./components/ApplicationStatus').default)
app.component('admin-update-application-button', require('./components/AdminUpdateApplicationButton').default)
app.component('custom-textarea', require('./components/CustomTextarea').default)
app.component('email-button', require('./components/EmailButton').default)


export const store = reactive({
    searchCount: null,
    additionalFilesCount: null,
    unreadMessagesCount: null,
    emailBody: '',
    adminApplicationStatus: null,
})

export const actions = {
    setSearchCount(number) {
        store.searchCount = number
    },
    setAdditionalFilesCount(number) {
        store.additionalFilesCount = number
    },
    setUnreadMessagesCount(number) {
        store.unreadMessagesCount = number
    },
    setAdminApplicationStatus(status) {
        store.adminApplicationStatus = status
    }
}

app.config.globalProperties.$store = store
app.config.globalProperties.$actions = actions

app.mount("#app")