<template>
    <div>
        <div class="card my-4">
            <div class="card-header">
                These files relate to the application and were uploaded after submission:
            </div>
            <div class="card-body">
                <ul class="list-group">
                    <li class="list-group-item" v-for="file in files">
                        <div class="d-flex justify-content-between align-items-center">
                            {{ file.application.reference }} - {{ file.file.name }}
                            <a :href="file.file.url" class="btn btn-primary">View</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    async mounted () {
        const { data } = await axios.get(`/admin/additional-files`)
        this.files = data
        this.$actions.setAdditionalFilesCount(Object.keys(data).length)
    },
    data() {
        return {
            files: []
        }
    },
    methods: {}
}
</script>
