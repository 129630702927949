<template>
    <select class="form-control form-select" v-model="selected" :name="name" @change="setApplicationStatus">
        <option
            :key="option"
            v-for="(option, key) in optionsForSelect"
            :value="key">
            {{ option }}
        </option>
    </select>
</template>

<script>
    export default {
        props: [
            'options', 'name', 'current'
        ],
        data() {
            return {
                'selected': null,
            }
        },
        computed: {
            optionsForSelect() {
                return this.options
            }
        },
        methods: {
            setApplicationStatus() {
                this.$actions.setAdminApplicationStatus(this.selected)
            }
        }
    }
</script>
