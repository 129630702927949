<style>
.word-count {
    display: flex;
    font-size: 11px;
    margin-top: 2px;
    justify-content: flex-end;
}
</style>

<template>
    <div>
        <div class="input-group">
            <textarea class="form-control"
                      :placeholder="placeholder"
                      :rows="rows"
                      :name="name"
                      v-model="content"
                      v-on:keyup="change"
            ></textarea>
        </div>
        <div class="word-count">Word Count: {{ wordCount }}</div>
    </div>

</template>

<script>

export default {
    props: ['placeholder', 'rows', 'name', 'value'],
    data () {
        return {
            content: this.value
        }
    },
    computed: {
        wordCount() {
            var num_words = 0;
            if ((this.content.match(/\S+/g)) != null) {
                num_words = this.content.match(/\S+/g).length;
            }
            return num_words;
        }
    }
}
</script>
