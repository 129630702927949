<template>
    <div class="my-4">
        <div class="row mb-2" v-for="file in currentFiles">
            <div class="col">
                <input type="hidden" :value="file.id" :name="fieldName" />
                {{ file.original_name }}
            </div>
            <div class="col">
                <div class="d-flex justify-content-end">
                    <a class="btn btn-danger" @click.prevent="removeFile(file)">Remove</a>
                </div>
            </div>
        </div>
        <input v-for="file in currentRemove" :name="fieldName + '_remove[]'" :value="file" type="hidden" />
    </div>
</template>

<script>
export default {
    props: ['files', 'fieldName', 'remove'],
    data() {
        return {
            removed: []
        }
    },
    methods: {
        removeFile(file) {
            this.removed.push(file.id)
        }
    },
    computed: {
        currentFiles() {
            return this.files.filter((f) => {
                return !this.currentRemove.includes(f.id)
            })
        },
        currentRemove() {
            return [...this.removed, ...this.remove.map(i => parseInt(i))]
        }
    }
}
</script>
