<template>
    <div class="input-group">
        <!--<date-picker v-model="date" :config="options" :wrap="true" placeholder="dd/mm/yyyy"></date-picker>-->
        <input type="hidden" :value="date" :name="name" />
        <input type="date" class="form-control custom-date" :value="dateVisible" :name="nameVisible" v-on:change="changeDate($event)" />
        <div class="input-group-append">
            <div class="input-group-text bg-primary">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-calendar-date" fill="#FFF" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                    <path d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
    jQuery("body").on("click", "input[type='date'].custom-date + .input-group-append", function() {
        jQuery(this).prev("input[type='date'].custom-date")[0].showPicker();
    });
    import moment from 'moment';
    /*import datePicker from 'vue-bootstrap-datetimepicker'
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'*/

    export default {
        /*components: { datePicker },*/
        props: ['name', 'value'],
        data () {
            return {
                date: this.value
            }
        },
        computed: {
            nameVisible() {
                return this.name + "__visible";
            },
            dateVisible() {
                if(this.date) {
                    var dateMomentObject = moment(this.date, "DD/MM/YYYY");
                    return dateMomentObject.format('YYYY-MM-DD')
                } else {
                    return '';
                }
            }
        },
        methods: {
            changeDate: function(event) {
                if(event.srcElement.value) {
                    var dateMomentObject = moment(event.srcElement.value, "YYYY-MM-DD");
                    if(dateMomentObject) {
                        this.date = dateMomentObject.format("DD/MM/YYYY");
                    }
                }
            }
        },
        data () {
            return {
                date: this.value,
                /*options: {
                    format: 'DD/MM/YYYY',
                    viewMode: 'years',
                    useCurrent: false,
                    icons: {
                        time: 'far fa-clock',
                        date: 'far fa-calendar',
                        up: 'fas fa-arrow-up',
                        down: 'fas fa-arrow-down',
                        previous: 'fas fa-chevron-left',
                        next: 'fas fa-chevron-right',
                        today: 'fas fa-calendar-check',
                        clear: 'far fa-trash-alt',
                        close: 'far fa-times-circle'
                    }
                }*/
            }
        }
    }
</script>
