<template>
    <div>
        <a class="small" @click="toggle">Additional Information</a>
        <portal to="modal" v-if="show">
            <modal :overlayClick="() => { this.show = false }">
                <slot></slot>
            </modal>
        </portal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false
        }
    },
    methods: {
        toggle() {
            this.show = !this.show
        }
    }
}
</script>
