<template>
    <div>
        <div v-for="item in inputs" :key="item">
            <div class="row" v-if="item === 1">
                <div class="col">
                    <file-input :field-name="fieldName + '[]'"></file-input>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col col-10">
                    <file-input :fieldName="fieldName + '[]'"></file-input>
                </div>
                <div class="col">
                    <button class="btn btn-danger" @click="remove(item)">Remove</button>
                </div>
            </div>
        </div>

        <a class="btn btn-primary" @click.prevent="add">Add Another File</a>
    </div>
</template>

<script>
export default {
    props: ['fieldName'],
    data() {
        return {
            inputs: [1]
        }
    },
    methods: {
        add() {
            this.inputs.push(this.inputs.length + 1)
        },
        remove(index) {
            const ditch = this.inputs.indexOf(index)
            this.inputs.splice(ditch, 1)
        }
    }
}
</script>
