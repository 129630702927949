<template>
    <div class="input-group custom-file mb-3">
        <input
            type="file"
            class="custom-file-input-- form-control"
            :class="{selected: name}"
            :id=inputID
            @change="onFileChange"
            :name="fieldName"
        >
        <label class="custom-file-label-- input-group-text" :for=inputID>{{ displayName }}</label>
    </div>
</template>

<script>
export default {
    props: ['fieldName'],
    data() {
        return {
            name: null
        }
    },
    methods: {
        onFileChange(e) {
            const file = e.target.files[0]
            if(file) {
                this.name = file.name
            } else {
                this.name = null
            }
        }
    },
    computed: {
        inputID() {
            return 'customFile'+Date.now()
        },
        displayName() {
            return this.name ?? 'Select File'
        }
    }
}
</script>
