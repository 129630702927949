<style>
.modal-overlay {
    background: rgba(17, 77, 108, 0.67);
    z-index: 1000;
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #EFE4D8;
    max-width: 722px;
    width: 100%;
    height: auto;
    max-height: 80%;
    padding: 30px;
    z-index: 1001;
    overflow-y: auto;
}

.modal h1, .modal p {
    color: #114D6C;
}

</style>

<template>
    <div>
        <div class="modal-overlay d-flex" @click.prevent="overlayClick"></div>
        <div class="modal d-flex">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ['overlayClick']
}
</script>
