<template>
    <div>
        <div class="message my-4" :class="{ read: message.admin_read_at }" v-for="message in messages">
            <h3><a :href="message.url">{{ message.subject }}</a> <i v-if="message.response_from_admin" class="fas fa-check green ms-2"></i></h3>
            <p>{{ message.touched_at }}</p>
        </div>

        <nav v-if="pagination">
            <ul class="pagination justify-content-center">
                <li v-for="link in pagination.links" class="page-item" :class="{ active: link.active, disabled: !link.url }">
                    <a class="page-link" href="#" v-html="link.label" @click.prevent="paginate(link.url)"></a>
                </li>
            </ul>
        </nav>
    </div>

</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            messages: null,
            pagination: null
        }
    },
    methods: {
        async getMessages() {
            const { data: { data, meta } } = await axios.get(`/admin/messages`)

            this.messages = data
            this.pagination = meta
        },
        async getUnreadCount() {
            const data = await axios.get(`/admin/messages/unread`)
            this.$actions.setUnreadMessagesCount(data.data)
        },
        async paginate(url) {
            const { data: { data, meta } } = await axios.get(url)

            this.messages = data
            this.pagination = meta
        }
    },
    mounted() {
        this.getMessages()
        this.getUnreadCount()
    }
}
</script>
