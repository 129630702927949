<template>
    <div>
        <button :name="name" :value="value" class="btn-primary btn" @click.prevent="submit">{{ display }}</button>
        <portal to="modal" v-if="show">
            <modal :overlayClick="() => { }">
                <form :action="action" method="POST" ref="form" class="w-100">
                    <input type="hidden" name="_method" :value="method">
                    <input type="hidden" name="_token" :value="csrf">
                    <div class="w-100">
                        <label>Email Body</label>
                        <textarea name="email" class="form-control" v-model="email" rows="4"></textarea>
                        <div class="mt-2">
                            <button class="btn btn-primary" :value="value" name="submitAction" type="submit">Submit</button>
                            <button class="btn btn-danger" @click.prevent="closeModal">Cancel</button>
                        </div>
                    </div>
                </form>
            </modal>
        </portal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            email: null
        }
    },
    props: ['name', 'value', 'display', 'form', 'action', 'method', 'csrf'],
    methods: {
        submit() {
            this.show = true
        },
        closeModal() {
            this.show = false
        }
    }
}
</script>
