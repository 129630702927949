<template>
    <span>
        <button type="submit" class="btn btn-primary" @click="displayEmailContent" ref="updateButton">Update</button>

        <input type="hidden" v-model="email" name="email" />

        <portal to="modal" v-if="show">
            <modal :overlayClick="() => { }">
                <div class="w-100">
                    <label>Email Body</label>
                    <textarea class="form-control" v-model="email" rows="4"></textarea>
                    <div class="mt-2">
                        <button class="btn btn-primary" @click.prevent="submitEmailBody">Submit</button>
                        <button class="btn btn-danger" @click.prevent="closeModal">Cancel</button>
                    </div>
                </div>
            </modal>
        </portal>
    </span>
</template>

<script>
    export default {
        props: ['messages'],
        data() {
            return {
                statuses: ['App\\States\\Application\\Successful', 'App\\States\\Application\\Rejected'],
                email: '',
                show: false,
            }
        },
        methods: {
            displayEmailContent(e) {
                const applicationStatus = this.$store.adminApplicationStatus;

                if (this.statuses.indexOf(applicationStatus) > -1) {
                    e.preventDefault()
                    this.show = true

                    let msg = ''

                    if (this.computedMessages.hasOwnProperty(applicationStatus.toLowerCase())) {
                        msg = this.computedMessages[applicationStatus.toLowerCase()]
                    }

                    this.email = msg
                }
            },
            submitEmailBody(e) {
                const form = this.$refs.updateButton.closest('form')
                form.submit()
            },
            closeModal(e) {
                this.show = false
            }
        },
        computed: {
            computedMessages() {
                return JSON.parse(this.messages)
            }
        }
    }
</script>
