<template>
    <div>
        <h1>Admin Dashboard</h1>

        <ul class="nav nav-tabs my-4" id="application-tabs" role="tablist">
            <li class="nav-item">
                <a href="#" class="nav-link" :class="{ active: tab === 'applications'}" @click.prevent="navigate('applications')">Applications <span class="badge rounded-pill bg-dark">{{ searchCount }}</span></a>
            </li>
            <li class="nav-item ms-1">
                <a href="#" class="nav-link" :class="{ active: tab === 'messages'}" @click.prevent="navigate('messages')">Messages <span class="badge rounded-pill bg-dark">{{ unreadMessagesCount }}</span></a>
            </li>
            <li class="nav-item ms-1" v-if="hasAdmin">
                <a href="#" class="nav-link" :class="{ active: tab === 'reports'}" @click.prevent="navigate('reports')">Reports</a>
            </li>
            <li class="nav-item ms-1">
                <a href="#" class="nav-link" :class="{ active: tab === 'additional-files'}" @click.prevent="navigate('additional-files')">Additional Files <span class="badge rounded-pill bg-dark d-none">{{ additionalFilesCount }}</span></a>
            </li>
        </ul>

        <div class="tab-content" v-show="tab === 'applications'">
            <application-search :states="states" :types="types"></application-search>
        </div>

        <div class="tab-content" v-show="tab === 'messages'">
            <messages></messages>
        </div>

        <div class="tab-content" v-show="tab === 'reports'" v-if="hasAdmin">
            <ul class="list-group">
                <li class="list-group-item list-group-item-info">
                    <strong>Emails</strong>
                    <p class="m-0 p-0">Import these lists into your MailChimp account.</p>
                </li>
                <li class="list-group-item">
                    <div class="d-flex justify-content-between align-items-center">
                        Applicants (in progress applications)
                        <a class="btn btn-primary" href="/admin/reports?report=mailchimp.in_progress_applicants">Download</a>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex justify-content-between align-items-center">
                        Applicants
                        <a class="btn btn-primary" href="/admin/reports?report=mailchimp.applicants">Download</a>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex justify-content-between align-items-center">
                        Beneficiaries
                        <a class="btn btn-primary" href="/admin/reports?report=mailchimp.beneficiaries">Download</a>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex justify-content-between align-items-center">
                        Alumni
                        <a class="btn btn-primary" href="/admin/reports?report=mailchimp.alumni">Download</a>
                    </div>
                </li>
            </ul>
            <ul class="list-group mt-4">
                <li class="list-group-item list-group-item-info">
                    <strong>Applications</strong>
                    <p class="m-0 p-0">Download these reports for your own records.</p>
                </li>
                <li class="list-group-item">
                    <div class="d-flex justify-content-between align-items-center">
                        New Awards
                        <a class="btn btn-primary" href="/admin/reports?report=applicants.new">Download</a>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex justify-content-between align-items-center">
                        All Awards
                        <a class="btn btn-primary" href="/admin/reports?report=applicants.all_awards">Download</a>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex justify-content-between align-items-center">
                        Unpaid Applications
                        <a class="btn btn-primary" href="/admin/reports?report=applicants.unpaid">Download</a>
                    </div>
                </li>
            </ul>


        </div>

        <div class="tab-content" v-show="tab === 'additional-files'">
            <additional-files></additional-files>
        </div>
    </div>
</template>


<script>

export default {
    props: ['states', 'types', 'is-admin'],
    data() {
        return {
            tab: 'applications'
        }
    },
    methods: {
        navigate(tab) {
            this.tab = tab
        }
    },
    computed: {
        searchCount() {
            return this.$store.searchCount
        },
        additionalFilesCount() {
            return this.$store.additionalFilesCount
        },
        unreadMessagesCount() {
            return this.$store.unreadMessagesCount
        },
        hasAdmin() {
            return this.isAdmin
        }
    }
}
</script>
